import React from "react"
import Image from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
// ...GatsbyImageSharpFluid
const query = graphql`
    query {
      file(relativePath: {eq: "hero-img.png"}) {
        id
        childImageSharp {
          fluid {
           ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

const Hero = () => {
  const { file: { childImageSharp: { fluid } } } = useStaticQuery(query)

  return (
    <header className="hero" >
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>Hi, I'm Ghislain</h1>
            <h4>Code lover, cloud adopter, web3 learner!</h4>
            <Link to="/contact" className="btn">Contact me</Link>
            <SocialLinks />
          </div>
        </article>
        <Image fluid={fluid} className="hero-img"></Image>
      </div>
    </header>
  )
}
export default Hero
