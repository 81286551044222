import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"


const query = graphql`
    query {
      allStrapiJob {
        nodes {
          date
          id
          position
          company
          order
          description {
            id
            name
            bullet
          }
        }
      }
    }
  `

const Jobs = () => {
  const data = useStaticQuery(query)

  let { allStrapiJob: { nodes: jobs } } = data
  jobs = jobs.sort((a, b) => b.order - a.order)
  const [value, setValue] = React.useState(0)
  const { company, position, date, description } = jobs[value]

  return <section className="section jobs">
    <Title title="experiences" />
    <div className="jobs-center">
      {/* {btn container} */}
      <div className="btn-container">
        {jobs.map((item, index) => {
          return (<button key={item.id}
            onClick={() => setValue(index)}
            className={`job-btn ${index === value && "active-btn"}`}>{item.company}
          </button>
          )
        })}
      </div>
      {/* Job info */}
      <article className="job-info">
        <h3>{position}</h3>
        <h4>{company}</h4>
        <p className="job-date">{date}</p>
        {description.map(item => {
          return <div key={item.id} className="job-desc">
            {item.bullet && <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>}
            <p>{item.name}</p>
          </div>
        })}
      </article>
    </div>
    <Link to="/about" className="btn center-btn">
      More info
    </Link>
  </section>
}

export default Jobs
